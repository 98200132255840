import type { MetaFunction } from "@remix-run/node";
import { Link } from "@remix-run/react";
import { Button } from "~/components/atoms";
import appIcon from "~/assets/appIcon.svg";
import { useTranslation } from "react-i18next";

export const meta: MetaFunction = () => {
  return [
    { title: "New Remix App" },
    { name: "description", content: "Welcome to Remix!" },
  ];
};

export default function Index() {
  const { t } = useTranslation("common");

  return (
    <div className="font-sans">
      <div
        id="landing_header"
        className="flex flex-row items-center justify-between border-b border-border p-4 "
      >
        <div className="flex flex-row gap-2 items-center text-accent-main font-bold">
          <img src={appIcon} className="w-8 aspect-square" />
          <p className="text-2xl">{t("appName")}</p>
        </div>

        <div className="flex flex-row gap-4">
          <Link to={"/"} className="text-accent-main font-medium">
            Precios
          </Link>
          <Link to={"/"} className="text-accent-main font-medium">
            Preguntas frecuentes
          </Link>
        </div>

        <div className="flex flex-row gap-2">
          <Link to="/register">
            <Button variant="flat">Registrarse</Button>
          </Link>
          <Link to="/login">
            <Button variant="filled">Ingresar</Button>
          </Link>
        </div>
      </div>
      {/* <Header>
        <Header.Menu className="flex-1">
          <Link to={"/"} className="text-accent-main font-medium">
            Precios
          </Link>
          <Link to={"/"} className="text-accent-main font-medium">
            Preguntas frecuentes
          </Link>
        </Header.Menu>

        <Header.Burttons>
          <Link to="/register">
            <Button variant="flat">Registrarse</Button>
          </Link>
          <Link to="/login">
            <Button variant="filled">Ingresar</Button>
          </Link>
        </Header.Burttons>
      </Header> */}
    </div>
  );
}
